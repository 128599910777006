<script setup lang="ts">
import { SlideoverDocs } from '#components'

// const { isDashboardSearchModalOpen } = useUIState()
// const { metaSymbol } = useShortcuts()

const route = useRoute()
const { clear, user } = useUserSession()
const colorMode = useColorMode()
const slideover = useSlideover()
const modal = useModal()

const signOut = async () => {
  await clear()
  return navigateTo('/auth/login')
}

const { slideoverOpenCreate } = useIssues()

const items = computed(() => [
  [
    {
      slot: 'account',
      label: '',
      disabled: true,
    },
  ],
  [
    {
      label: colorMode.value === 'dark' ? 'Modo oscuro' : 'Modo claro',
      slot: 'colorMode',
    },
  ],
  [
    {
      label: 'Documentación',
      icon: 'i-mdi-book-open-variant',
      click: () => openSlideoverDocs(),
    },
    {
      label: 'Reportar un problema',
      icon: 'i-mdi-message-question',
      click: () => slideoverOpenCreate({ data: {} }),
    },
    {
      label: 'Ajustes',
      icon: 'i-mdi-cog',
      to: '/settings',
    },
  ],
  [
    {
      label: 'Cerrar sesión',
      icon: 'i-mdi-logout',
      click: signOut,
    },
  ],
])

const openSlideoverDocs = () => slideover.open(SlideoverDocs, {
  tableName: route.path?.split('/')?.[2],
})
</script>

<template>
  <UDropdown
    :items="items"
    :ui="{ width: 'min-w-max w-full', item: { disabled: 'cursor-text select-text' } }"
    :popper="{ strategy: 'absolute', placement: 'top' }"
    class="w-full"
  >
    <template #default="{ open }">
      <UButton
        color="gray"
        variant="ghost"
        class="w-full"
        :class="[
          open && 'bg-primary-200/50 dark:bg-primary-800/50',
          'hover:bg-primary-200/50 dark:hover:bg-primary-800/50',
        ]"
      >
        <template #leading>
          <UAvatar
            :src="user?.image"
            :alt="user?.name"
            size="2xs"
          />
        </template>

        <span class="truncate max-w-[200px]">
          {{ user?.name }}
        </span>

        <template #trailing>
          <UIcon
            name="i-mdi-dots-horizontal"
            class="w-5 h-5 ml-auto"
          />
        </template>
      </UButton>
    </template>

    <template #account>
      <UAvatar
        :src="user?.image"
        :alt="user?.name"
        size="xs"
        class="mr-1"
      />
      <div class="text-left">
        <p>
          {{ user?.name ?? 'Sesión iniciada como' }}
        </p>
        <p class="truncate font-medium text-gray-900 dark:text-white text-xs">
          {{ user?.email }}
        </p>
      </div>
    </template>

    <template #colorMode="{ item }">
      <div class="w-full inline-flex justify-between">
        {{ item.label }}
        <UColorModeToggle />
      </div>
    </template>
  </UDropdown>
</template>
